import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { environment } from "environments/environment";
import { delay, retry } from "rxjs/operators";
import { AuthService } from "./auth.service";
import { CartToRedeemBulkRequest } from "../interfaces/cart-list";

@Injectable({
  providedIn: "root",
})
export class PointService {
  public retryNo: number = 3;
  public delayNo: number = 1000;

  constructor(private _http: HttpClient, private _authService: AuthService) {}

  getRewardStatus() {
    return [
      { id: 0, name: "Verification", badge: "badge-secondary" },
      { id: 1, name: "On process", badge: "badge-success" },
      { id: 2, name: "Rejected", badge: "badge-danger" },
      { id: 3, name: "On delivery", badge: "badge-info" },
      { id: 4, name: "Done", badge: "badge-success" },
      { id: 5, name: "Email Validating", badge: "badge-secondary" },
      { id: 6, name: "Canceled", badge: "badge-warning" },
      { id: 7, name: "Complete", badge: "badge-primary" },
      { id: 8, name: "Confirm Data", badge: "badge-primary" },
      { id: 9, name: "On process", badge: "badge-success" },
      { id: 10, name: "On cart", badge: "badge-success" },
    ];
  }
  getRewardStatusForCart() {
    return [
      { id: 0, name: "Verification", badge: "badge-secondary" },
      { id: 1, name: "On process", badge: "badge-success" },
      { id: 2, name: "Rejected", badge: "badge-danger" },
      { id: 3, name: "On delivery", badge: "badge-info" },
      { id: 4, name: "Done", badge: "badge-success" },
      { id: 5, name: "Email Validating", badge: "badge-secondary" },
      { id: 6, name: "Canceled", badge: "badge-warning" },
      { id: 7, name: "Complete", badge: "badge-primary" },
      { id: 8, name: "Confirm Data", badge: "badge-primary" },
      { id: 9, name: "On process", badge: "badge-success" },
      { id: 10, name: "On cart", badge: "badge-success" },
    ];
  }

  getCurrentPoint() {
    interface ApiResponse {
      status: boolean;
      message: string;
      data: Data;
    }

    interface Data {
      active: number;
      current_point: number;
      hold: number;
      id: number;
      is_block: boolean;
    }

    return (
      this._http
        // .get(`${`http://localhost:9087`}/currentPoint`)
        // return this._http
        .get<ApiResponse>(`${environment.ssPointUrl}/history/currentPoint`)
        .pipe(retry(this.retryNo), delay(this.delayNo))
    );
  }

  getReferralTree(pData: FormGroup) {
    let httpParams;
    if (this._authService.isHO) {
      httpParams = new HttpParams().set(
        "parent_username",
        pData.value.userName
      );
    }
    return (
      this._http
        // .get(`${`http://localhost:9082`}/referralTree`, { params: httpParams })
        // return this._http
        .get(`${environment.ssPointUrl}/referral/referralTree`, {
          params: httpParams,
        })
        .pipe(retry(this.retryNo), delay(this.delayNo))
    );
  }

  getHistoryPoint(pData: FormGroup) {
    var httpParams;
    httpParams = new HttpParams()
      .set("from_trx_date", pData.value.fromTrxDate)
      .set("to_trx_date", pData.value.toTrxDate);

    if (this._authService.isHO) {
      httpParams = httpParams.append("username", pData.value.username);
    }
    return (
      this._http
        // .get(`http://localhost:9087/pointHistory`, { params: httpParams })
        // return this._http
        .get(`${environment.ssPointUrl}/history/pointHistory`, {
          params: httpParams,
        })
        .pipe(retry(this.retryNo), delay(this.delayNo))
    );
  }

  getReward() {
    return (
      this._http
        // .get(`http://localhost:9084/rewardList`)
        // return this._http
        .get(`${environment.ssPointUrl}/reward/rewardList`)
        .pipe(retry(this.retryNo), delay(this.delayNo))
    );
  }

  getCategoryReward() {
    return (
      this._http
        // .get(`${`http://localhost:9089`}/rewardCategory`)
        .get(`${environment.ssPointUrl}/rwd/rewardCategory`)
        .pipe(retry(this.retryNo), delay(this.delayNo))
    );
  }

  storeCategoryReward(pData: FormGroup) {
    const body = JSON.stringify({
      rewardName: pData.value.frmCateName,
    });
    return (
      this._http
        // .post(`${`http://localhost:9089`}/rewardCategory`, body)
        .post(`${environment.ssPointUrl}/rwd/rewardCategory`, body)
        .pipe(retry(this.retryNo), delay(this.delayNo))
    );
  }

  putCategoryReward(pData: FormGroup) {
    const body = JSON.stringify({
      idReward: pData.value.frmCateId,
      rewardName: pData.value.frmCateName,
    });
    return (
      this._http
        // .put(`${`http://localhost:9089`}/rewardCategory`, body)
        .put(`${environment.ssPointUrl}/rwd/rewardCategory`, body)
        .pipe(retry(this.retryNo), delay(this.delayNo))
    );
  }

  getRedeemRequest(pData: FormGroup) {
    var httpParams;
    httpParams = new HttpParams()
      .set("from_trx_date", pData.value.fromTrxDate)
      .set("to_trx_date", pData.value.toTrxDate);

    if (pData.value.rdmStatus != null) {
      let status = "";
      pData.value.rdmStatus.forEach((el) => {
        status += "," + el.id;
      });
      httpParams = httpParams.append("status", status.substring(1));
    }

    if (pData.value.rewardCate != null) {
      let category = "";
      pData.value.rewardCate.forEach((el) => {
        category += "," + el.rewardCateId;
      });
      httpParams = httpParams.append("category", category.substring(1));
    }

    if (this._authService.isHO) {
      if (pData.value.userName != null) {
        httpParams = httpParams.append("username", pData.value.userName);
      }
    }

    return (
      this._http
        // .get(`http://localhost:9091/requestRedeem`, { params: httpParams })
        // return this._http
        .get(`${environment.ssPointUrl}/redeem/requestRedeem`, {
          params: httpParams,
        })
        .pipe(retry(this.retryNo), delay(this.delayNo))
    );
  }

  getCart(pData: FormGroup) {
    var httpParams;
    httpParams = new HttpParams().set("status", pData.value.rdmStatus);
    //   .set("from_trx_date", pData.value.fromTrxDate)
    //   .set("to_trx_date", pData.value.toTrxDate);

    if (this._authService.isHO) {
      if (pData.value.userName != null) {
        httpParams = httpParams.append("username", pData.value.userName);
      }
    }

    return (
      this._http
        // .get(`http://localhost:9091/cart`, { params: httpParams })
        // return this._http
        .get(`${environment.ssPointUrl}/redeem/cart`, { params: httpParams })
        .pipe(retry(this.retryNo), delay(this.delayNo))
    );
  }

  storeToCart(param_reward_id: number, note: any) {
    const body = JSON.stringify({
      reward_id: param_reward_id,
      note: note,
    });
    return (
      this._http
        // .post(`http://localhost:9091/cart`, body)
        // return this._http
        .post(`${environment.ssPointUrl}/redeem/cart`, body)
        .pipe(retry(this.retryNo), delay(this.delayNo))
    );
  }

  deleteFromCart(requestId: number) {
    const body = JSON.stringify({
      // reqId: requestId,
    });
    return (
      this._http
        // .put(`http://localhost:9091/removeCart/${requestId}`, body)
        // return this._http
        .put(`${environment.ssPointUrl}/redeem/removeCart/${requestId}`, body)
        .pipe(retry(this.retryNo), delay(this.delayNo))
    );
  }

  storeBulkRedeemRequest(input: CartToRedeemBulkRequest[]) {
    const body = JSON.stringify(input);
    return (
      this._http
        // .post(`http://localhost:9091/claimBulkRedeem`, body)
        // return this._http
        .post(`${environment.ssPointUrl}/redeem/claimBulkRedeem`, body)
        .pipe(retry(this.retryNo), delay(this.delayNo))
    );
  }

  storeRedeemRequest(param_reward_id: number, note: any) {
    const body = JSON.stringify({
      reward_id: param_reward_id,
      note: note,
    });

    return (
      this._http
        // .post(`http://localhost:9091/claimRedeem`, body)
        // return this._http
        .post(`${environment.ssPointUrl}/redeem/claimRedeem`, body)
        .pipe(retry(this.retryNo), delay(this.delayNo))
    );
  }

  putRedeemRequest(pData: FormGroup, catId: number, resi: string) {
    let note = pData.value.reqNote == "" ? null : pData.value.reqNote;
    const body = JSON.stringify({
      id: pData.value.reqId,
      status_validation: pData.value.reqStatId,
      note: note,
      resi: resi,
      qty: pData.value.reqQty,
    });

    return (
      this._http
        // .put(`http://localhost:9091/requestRedeem/${catId}`, body)
        // return this._http
        .put(`${environment.ssPointUrl}/redeem/requestRedeem/${catId}`, body)
        .pipe(retry(this.retryNo), delay(this.delayNo))
    );
  }

  cancelRedeemRequest(id_redeem: number, qty: number) {
    const body = JSON.stringify({
      id: id_redeem,
      qty: qty,
    });
    return (
      this._http
        // .post(`http://localhost:9091/cancelRedeem`, body)
        // return this._http
        .post(`${environment.ssPointUrl}/redeem/cancelRedeem`, body)
        .pipe(retry(this.retryNo), delay(this.delayNo))
    );
  }

  completeRedeemRequest(id_redeem: number) {
    const body = JSON.stringify({
      id: id_redeem,
    });
    return (
      this._http
        // .post(`http://localhost:9091/completeRedeem`, body)
        // return this._http
        .post(`${environment.ssPointUrl}/redeem/completeRedeem`, body)
        .pipe(retry(this.retryNo), delay(this.delayNo))
    );
  }

  blacklistRedeemGps(redeem_no: string, imei: string) {
    const body = JSON.stringify({
      imei: imei,
      redeem_no: redeem_no,
    });
    return (
      this._http
        // .post(`http://localhost:9091/redeemGps`, body)
        // return this._http
        .post(`${environment.ssPointUrl}/redeem/redeemGps`, body)
        .pipe(retry(this.retryNo), delay(this.delayNo))
    );
  }

  /**
   * Function untuk melakukan post blacklist multiple imei dari sisi HO
   * Function ini menerima parameter redeem number, imei, dan redeem quantity
   * Function ini tidak memiliki nilai return apapun (async)
   *
   * Perubahan pertama: function baru
   * Perubahan by Aldy - 24 Mei 2024
   */
  blacklistRedeemMultipleGps(
    redeem_no: string,
    imei: string,
    redeem_qty: number
  ) {
    const body = JSON.stringify({
      imei: imei,
      redeem_no: redeem_no,
      redeem_qty: redeem_qty,
    });
    return (
      this._http
        // .post(`http://localhost:9091/redeemMultipleGps`, body)
        // return this._http
        .post(`${environment.ssPointUrl}/redeem/redeemMultipleGps`, body)
        .pipe(retry(this.retryNo), delay(this.delayNo))
    );
  }

  dataSimUser() {
    return (
      this._http
        // .get(`http://localhost:9091/gsmUser`)
        // return this._http
        .get(`${environment.ssPointUrl}/redeem/gsmUser`)
        .pipe(retry(this.retryNo), delay(this.delayNo))
    );
  }

  resendEmailRedeem(reward_id: number) {
    const body = JSON.stringify({
      reward_id: reward_id,
    });
    return (
      this._http
        // .post(`http://localhost:9091/resendEmail`, body)
        // return this._http
        .post(`${environment.ssPointUrl}/redeem/resendEmail`, body)
        .pipe(retry(this.retryNo), delay(this.delayNo))
    );
  }

  cancelRedeemPulsa(reward_id: number, note: string) {
    const body = JSON.stringify({
      reward_id: reward_id,
      note: note,
    });
    return (
      this._http
        // .post(`http://localhost:9091/cancelTopup`, body)
        // return this._http
        .post(`${environment.ssPointUrl}/redeem/cancelTopup`, body)
        .pipe(retry(this.retryNo), delay(this.delayNo))
    );
  }
}
