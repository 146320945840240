import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { CoreCommonModule } from '@core/common.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';

import { AuthenticationModule } from './authentication/authentication.module';
import { RouterModule, Routes } from '@angular/router';
import { ErrorInterceptorProvider } from '../helpers/error.interceptor';
import { JwtInterceptorProvider } from '../helpers/jwt.interceptor';
import { JSONInterceptorProvider } from '../helpers/json.interceptor';

// routing
const routes: Routes = [
  {
    path: 'head',
    loadChildren: () => import('./head-office/head-office.module').then(m => m.HeadOfficeModule)
  },
  {
    path: 'branch',
    loadChildren: () => import('./branch/branch.module').then(m => m.BranchModule)
  },
  {
    path: 'cust',
    loadChildren: () => import('./customer/customer.module').then(m => m.CustomerModule)
  },
  {
    path: 'misc',
    loadChildren: () => import('./miscellaneous/miscellaneous.module').then(m => m.MiscellaneousModule)
  },
];

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    CoreCommonModule,
    ContentHeaderModule,
    NgbModule,
    NgSelectModule,
    FormsModule,
    AuthenticationModule,
    RouterModule.forChild(routes),
  ],

  providers: [
    JSONInterceptorProvider,
    JwtInterceptorProvider,
    ErrorInterceptorProvider,
  ],
})
export class PagesModule { }
