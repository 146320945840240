import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class JsonInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    switch (request.method) {
      case 'POST':
        request = request.clone({
          setHeaders: {
            'Content-Type': 'application/json'
          }
        });
        break;

      case 'PUT':
        request = request.clone({
          setHeaders: {
            'Content-Type': 'application/json'
          }
        });
        break;

      default:
        request = request.clone({
          setHeaders: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        });
        break;
    }

    return next.handle(request);
  }
}

export const JSONInterceptorProvider = {
  // use fake backend in place of Http service for backend-less development
  provide: HTTP_INTERCEPTORS,
  useClass: JsonInterceptor,
  multi: true
};
