export const locale = {
  lang: "en",
  data: {
    MENU: {
      HOME: "Home",
      REFERRAL: "Referral",
      POINT: "Point",
      POINT_LIST: "Point History",
      POINT_MASTER: "Point Master",
      REWARD: "Reward",
      CART: "Cart",
      REWARD_LIST: "Reward List",
      REWARD_CATEGORY: "Reward Category",
      REDEEM: "Redeem",
      FAQ: "FAQs",
      TNC: "Term Condition",
      SAMPLE: "Sample",
    },
  },
};
