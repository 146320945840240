import {
  Component,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { MediaObserver } from "@angular/flex-layout";

import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { CoreConfigService } from "@core/services/config.service";
import { CoreMediaService } from "@core/services/media.service";
import { PointService } from "app/main/services/point.service";

import { NavigationStart, Router } from "@angular/router";
import { User } from "app/main/models/user";
import { AuthService } from "app/main/services/auth.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit, OnDestroy {
  public horizontalMenu: boolean;
  public hiddenMenu: boolean;
  public myPoint: number;
  public pointActive: number;
  public pointHold: number;
  public coreConfig: any;
  public currentSkin: string;
  public prevSkin: string;

  public result: any;
  public currentUser: User;

  public languageOptions: any;
  public navigation: any;
  public selectedLanguage: any;

  public currentUrl: string;
  public counterCurrentPoint = 0;

  @HostBinding("class.fixed-top")
  public isFixed = false;

  @HostBinding("class.navbar-static-style-on-scroll")
  public windowScrolled = false;

  // Add .navbar-static-style-on-scroll on scroll using HostListener & HostBinding
  @HostListener("window:scroll", [])
  onWindowScroll() {
    if (
      (window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop > 100) &&
      this.coreConfig.layout.navbar.type == "navbar-static-top" &&
      this.coreConfig.layout.type == "horizontal"
    ) {
      this.windowScrolled = true;
    } else if (
      (this.windowScrolled && window.pageYOffset) ||
      document.documentElement.scrollTop ||
      document.body.scrollTop < 10
    ) {
      this.windowScrolled = false;
    }
  }

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {Router} _router
   * @param {AuthenticationService} _authService
   * @param {CoreConfigService} _coreConfigService
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CoreMediaService} _coreMediaService
   * @param {MediaObserver} _mediaObserver
   * @param {TranslateService} _translateService
   */
  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _coreConfigService: CoreConfigService,
    private _coreMediaService: CoreMediaService,
    private _pointService: PointService,
    private _coreSidebarService: CoreSidebarService,
    private _mediaObserver: MediaObserver,
    public _translateService: TranslateService
  ) {
    this._authService.currentUser.subscribe((x) => (this.currentUser = x));

    this.languageOptions = {
      en: {
        title: "English",
        flag: "us",
      },
      id: {
        title: "Indonesia",
        flag: "id",
      },
      de: {
        title: "German",
        flag: "de",
      },
      pt: {
        title: "Portuguese",
        flag: "pt",
      },
    };

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebar(key): void {
    this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
  }

  /**
   * Set the language
   *
   * @param language
   */
  setLanguage(language): void {
    // Set the selected language for the navbar on change
    this.selectedLanguage = language;

    // Use the selected language id for translations
    this._translateService.use(language);

    this._coreConfigService.setConfig(
      { app: { appLanguage: language } },
      { emitEvent: true }
    );
  }

  /**
   * Toggle Dark Skin
   */
  toggleDarkSkin() {
    // Get the current skin
    this._coreConfigService
      .getConfig()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.currentSkin = config.layout.skin;
      });

    // Toggle Dark skin with prevSkin skin
    this.prevSkin = localStorage.getItem("prevSkin");

    if (this.currentSkin === "dark") {
      this._coreConfigService.setConfig(
        { layout: { skin: this.prevSkin ? this.prevSkin : "default" } },
        { emitEvent: true }
      );
    } else {
      localStorage.setItem("prevSkin", this.currentSkin);
      this._coreConfigService.setConfig(
        { layout: { skin: "dark" } },
        { emitEvent: true }
      );
    }
  }

  /**
   * Logout method
   */
  logout() {
    this._authService.logout();
    this._router.navigate(["/login"]);
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // get the currentUser details from localStorage
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));

    // Subscribe to the config changes
    this._coreConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.coreConfig = config;
        this.horizontalMenu = config.layout.type === "horizontal";
        this.hiddenMenu = config.layout.menu.hidden === true;
        this.currentSkin = config.layout.skin;

        // Fix: for vertical layout if default navbar fixed-top than set isFixed = true
        if (this.coreConfig.layout.type === "vertical") {
          setTimeout(() => {
            if (this.coreConfig.layout.navbar.type === "fixed-top") {
              this.isFixed = true;
            }
          }, 0);
        }
      });

    // let allowedRoutes: string[] = ["/"];
    // allowedRoutes.forEach((url) => {
    //   if (url !== this._router.url) {
    //     this.getCurrentPoint();
    //   }
    // });

    // this._router.events.forEach((event) => {
    //   if (event instanceof NavigationStart) {
    //     console.log(event["url"]);
    //     this.getCurrentPoint();
    //     if (!event["url"].includes("/login/")) {
    //       // console.log(event["url"]);
    //     }
    //   }
    // });

    this.getCurrentPoint();

    // Horizontal Layout Only: Add class fixed-top to navbar below large screen
    if (this.coreConfig.layout.type == "horizontal") {
      // On every media(screen) change
      this._coreMediaService.onMediaUpdate
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(() => {
          const isFixedTop = this._mediaObserver.isActive("bs-gt-xl");
          if (isFixedTop) {
            this.isFixed = false;
          } else {
            this.isFixed = true;
          }
        });
    }

    // Set the selected language from default languageOptions
    this.selectedLanguage = _.find(this.languageOptions, {
      id: this._translateService.currentLang,
    });
  }

  async getCurrentPoint() {
    // await this._pointService
    //   .getCurrentPoint()
    //   .pipe(
    //     tap((result) => {
    //       setTimeout(() => {
    //         this.myPoint = result.data.current_point;
    //         this.pointActive = result.data.active;
    //         this.pointHold = result.data.hold;
    //       }, 1000).refresh;

    //       () => {
    //         this._ngZone.run(() => {
    //           console.log("kepanggil ng zone");
    //           this.myPoint = result.data.current_point;
    //           this.pointActive = result.data.active;
    //           this.pointHold = result.data.hold;
    //         });
    //       };

    //       this.myPoint = result.data.current_point;
    //       this.pointActive = result.data.active;
    //       this.pointHold = result.data.hold;
    //     })
    //   )
    //   .toPromise();
    // .then((result) => {
    //   this.result = result;
    //   // localStorage.setItem("resultPoint", JSON.stringify(this.result));
    // })
    // .finally(() => {
    //   // let resultPoint = JSON.parse(localStorage.getItem("resultPoint"));
    //   // this.myPoint = resultPoint.data.current_point;
    //   // this.pointActive = resultPoint.data.active;
    //   // this.pointHold = resultPoint.data.hold;

    //   setTimeout(() => {
    //     this.myPoint = this.result.data.current_point;
    //     this.pointActive = this.result.data.active;
    //     this.pointHold = this.result.data.hold;
    //   }, 1000).refresh;
    // });

    this.result = await this._pointService.getCurrentPoint().toPromise();
    if (!this.result.status) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: this.result.message,
      });
      return;
    }
    this.myPoint = this.result.data.current_point;
    this.pointActive = this.result.data.active;
    this.pointHold = this.result.data.hold;
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
}
