import { CoreMenu } from "@core/types";

export const custMenu: CoreMenu[] = [
  {
    id: "home",
    title: "Home",
    translate: "MENU.HOME",
    type: "item",
    icon: "home",
    url: "cust/home",
    role: ["Customer"],
  },
  {
    id: "referral",
    title: "Referral",
    translate: "MENU.REFERRAL",
    type: "item",
    icon: "users",
    url: "cust/referral",
    role: ["Customer"],
  },
  {
    id: "point",
    title: "Point",
    translate: "MENU.POINT",
    type: "item",
    icon: "dollar-sign",
    url: "cust/point",
    role: ["Customer"],
  },
  {
    id: "reward",
    title: "Reward",
    translate: "MENU.REWARD",
    type: "item",
    icon: "gift",
    url: "cust/reward",
    role: ["Customer"],
  },
  {
    id: "cart",
    title: "Cart",
    translate: "MENU.CART",
    type: "item",
    icon: "shopping-cart",
    url: "cust/cart",
    role: ["Customer"],
  },
  {
    id: "redeem",
    title: "Redeem",
    translate: "MENU.REDEEM",
    type: "item",
    icon: "minimize-2",
    url: "cust/redeem",
    role: ["Customer"],
  },
];
