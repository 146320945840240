import { CoreMenu } from '@core/types'

export const headMenu: CoreMenu[] = [
  {
    id: 'home',
    title: 'Home',
    translate: 'MENU.HOME',
    type: 'item',
    icon: 'home',
    url: 'head/home',
    role: ['Head Office']
  },
  {
    id: 'referral',
    title: 'Referral',
    translate: 'MENU.REFERRAL',
    type: 'item',
    icon: 'users',
    url: 'head/referral',
    role: ['Head Office']
  },
  {
    id: 'point',
    title: 'Point',
    translate: 'MENU.POINT',
    type: 'collapsible',
    icon: 'dollar-sign',
    role: ['Head Office'],
    children: [
      {
        id: 'point-list',
        title: 'Point List',
        translate: 'MENU.POINT_LIST',
        type: 'item',
        icon: 'circle',
        url: 'head/point/list',
        role: ['Head Office']
      },
      // {
      //   id: 'point-master',
      //   title: 'Point Master',
      //   translate: 'MENU.POINT_MASTER',
      //   type: 'item',
      //   icon: 'circle',
      //   url: 'head/point/master',
      //   role: ['Head Office']
      // }
    ],
  },
  {
    id: 'reward',
    title: 'Reward',
    translate: 'MENU.REWARD',
    type: 'collapsible',
    icon: 'gift',
    role: ['Head Office'],
    children: [
      {
        id: 'reward-categeory',
        title: 'Reward Category',
        translate: 'MENU.REWARD_CATEGORY',
        type: 'item',
        icon: 'circle',
        url: 'head/reward/category',
        role: ['Head Office']
      },
      {
        id: 'reward-list',
        title: 'Reward List',
        translate: 'MENU.REWARD_LIST',
        type: 'item',
        icon: 'circle',
        url: 'head/reward/list',
        role: ['Head Office']
      },
    ],
  },
  {
    id: 'redeem',
    title: 'Redeem',
    translate: 'MENU.REDEEM',
    type: 'item',
    icon: 'minimize-2',
    url: 'head/redeem',
    role: ['Head Office']
  }
]
