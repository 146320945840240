import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { delay, retry } from "rxjs/operators";
import { Role } from "../models/role";
import { User } from "../models/user";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  //public
  public currentUser: Observable<User>;

  //private
  private _currentUserSubject: BehaviorSubject<User>;
  private _profile: any;
  private _retryNo: number = 3;
  private _delayNo: number = 300;

  constructor(private _router: Router, private _http: HttpClient) {
    // this._currentUserSubject = new BehaviorSubject<User>(null);
    // let localUser = JSON.parse(localStorage.getItem("currentUser"));

    // if (
    //   localUser === null ||
    //   localUser === undefined ||
    //   localUser.length === 0
    // ) {
    //   this._currentUserSubject = new BehaviorSubject<User>(null);
    // } else {
    //   this._currentUserSubject = new BehaviorSubject<User>(
    //     JSON.parse(localStorage.getItem("currentUser"))
    //   );
    // }

    // this.currentUser = this._currentUserSubject.asObservable();

    this._currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this._currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this._currentUserSubject.value;
  }

  get isHO() {
    return this.currentUser && this._currentUserSubject.value.role === Role.HO;
  }

  get isBranch() {
    return (
      this.currentUser && this._currentUserSubject.value.role === Role.Branch
    );
  }

  get isCustomer() {
    return (
      this.currentUser && this._currentUserSubject.value.role === Role.Customer
    );
  }

  get userToken() {
    return this.currentUserValue.token;
  }

  get getUsername() {
    return this.currentUserValue.username;
  }

  async getProfile(pToken: string) {
    const reqHeader = new HttpHeaders({
      "Content-Type": "application/json",
      Authorization: `Bearer ${pToken}`,
    });

    interface ApiResponse {
      status: boolean;
      message: Message;
    }

    interface Message {
      data: Data;
    }

    interface Data {
      id: number;
      apps: Apps;
      branch: Branch;
      privilege: number;
      label: string;
      fullname: string;
      company_name: string | null;
      email: string;
      phone: string;
      address: string;
      username: string;
      status: number;
      type: number;
      is_verified_phone: boolean;
      is_verified_email: boolean;
      verification_otp: boolean;
      show_verification: boolean;
      enable_skip: boolean;
      join_date: string; // DateTime in ISO 8601 format
    }

    interface Apps {
      id: number;
      user_id: number;
      apps: string; // JSON array in string format
    }

    interface Branch {
      id: number;
      pic_id: number | null;
      name: string;
      address: string;
      phone: string;
      deleted_at: string | null;
      deleted_by: string | null;
      category: number;
      lon: string;
      lat: string;
      type_branch: number;
      store_region_id: number;
    }

    var user = new User();
    await this._http
      .get<ApiResponse>(`${environment.portalBEUrl}/backend/api/profile`, {
        headers: reqHeader,
      })
      .pipe(retry(this._retryNo), delay(this._delayNo))
      .toPromise()
      .then((result) => {
        user = {
          id: result.message.data.id,
          username: result.message.data.username,
          email: result.message.data.email,
          fullName: result.message.data.fullname,
          label: result.message.data.label,
          role: this.setRole(result.message.data.privilege),
          phone: result.message.data.phone,
          token: pToken,
        };
      })
      .finally(() => {
        localStorage.setItem("currentUser", JSON.stringify(user));

        let localUser = JSON.parse(localStorage.getItem("currentUser"));

        if (
          localUser !== null ||
          localUser !== undefined ||
          localUser.length > 0
        ) {
          this._currentUserSubject.next(user);

          this.goToHome();
        }
      });

    // old codebase
    // this._profile = await this._http
    //   .get<ApiResponse>(`${environment.portalBEUrl}/backend/api/profile`, {
    //     headers: reqHeader,
    //   })
    //   .pipe(retry(this._retryNo), delay(this._delayNo))
    //   .toPromise();

    // var user = {
    //   id: this._profile.message.data.id,
    //   username: this._profile.message.data.username,
    //   email: this._profile.message.data.email,
    //   fullName: this._profile.message.data.fullname,
    //   label: this._profile.message.data.label,
    //   role: this.setRole(this._profile.message.data.privilege),
    //   phone: this._profile.message.data.phone,
    //   token: pToken,
    // };
    // localStorage.setItem("currentUser", JSON.stringify(user));

    // this._currentUserSubject.next(user);
    // this.goToHome();
  }

  setRole(privilageId: number) {
    switch (privilageId) {
      case 1:
        return Role.HO;

      case 2:
        return Role.HO;

      case 3:
        return Role.Branch;

      case 4:
        return Role.Customer;

      case 5:
        return Role.Customer;

      case 6:
        return Role.Customer;

      default:
        return Role.Customer;
    }
  }

  goToHome() {
    setTimeout(() => {
      if (this.isHO) {
        this._router.navigate(["/head/home"]);
      } else if (this.isBranch) {
        this._router.navigate(["/branch/home"]);
      } else if (this.isCustomer) {
        this._router.navigate(["/cust/home"]);
      }
    });
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    // notify
    this._currentUserSubject.next(null);
  }
}
