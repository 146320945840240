import { CoreMenu } from "@core/types";

export const branchMenu: CoreMenu[] = [
  {
    id: "home",
    title: "Home",
    translate: "MENU.HOME",
    type: "item",
    icon: "home",
    url: "branch/home",
    role: ["Branch"],
  },
  {
    id: "point",
    title: "Point",
    translate: "MENU.POINT",
    type: "item",
    icon: "disc",
    url: "branch/point",
    role: ["Branch"],
  },
  {
    id: "reward",
    title: "Reward",
    translate: "MENU.REWARD",
    type: "item",
    icon: "dollar-sign",
    url: "branch/reward",
    role: ["Branch"],
  },
  {
    id: "cart",
    title: "Cart",
    translate: "MENU.CART",
    type: "item",
    icon: "shopping-cart",
    url: "branch/cart",
    role: ["Branch"],
  },
  {
    id: "redeem",
    title: "Redeem",
    translate: "MENU.REDEEM",
    type: "item",
    icon: "gift",
    url: "branch/redeem",
    role: ["Branch"],
  },
];
