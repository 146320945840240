export const locale = {
  lang: "id",
  data: {
    MENU: {
      HOME: "Beranda",
      REFERRAL: "Referral",
      POINT: "Poin",
      POINT_LIST: "Riwayat Poin",
      POINT_MASTER: "Master Poin",
      REWARD: "Hadiah",
      CART: "Keranjang",
      REWARD_LIST: "Daftar Hadiah",
      REWARD_CATEGORY: "Master Kategori",
      REDEEM: "Tukar Poin",
      FAQ: "Pertanyaan",
      TNC: "Syarat dan Ketentuan",
      SAMPLE: "Sample",
    },
  },
};
