import { Role } from './role';

export class User {
    id: number;
    username:string;
    email: string;
    fullName: string;
    label: string;
    role: Role;
    phone: string;
    token?: string;
}
